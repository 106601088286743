@import "../_vars";
@import "../_mixins";
.Button {
  border: 0;
  padding: 0.5em 3em;
  color: $color-light;
  background-color: $color-accent;
  font-weight: bold;
  font-size: larger;
  @include clickable;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
}
