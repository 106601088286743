@import "../_vars";
@import "../_mixins";
.Footer {
  text-align: center;
  background-color: $color-light-2;
  padding: 2em;
  .menu {
    @include breakpoint-desktop {
      display: flex;
      justify-content: center;
    }
    & > * {
      margin: 2em;
    }
  }
}
