@import "../_vars";
@import "../_mixins";
.Header {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .top {
    font-size: 0.7em;
    background-color: $color-light-2;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin: 0 20px;
        padding: 6px 0;
      }
    }
  }
  .main {
    font-size: $size-small;
    color: $color-dark;
    .logo {
      margin-top: 1.65em;
      height: 43px;
    }
    nav {
      display: none;
      @include breakpoint-desktop {
        display: block;
      }
      ul {
        display: flex;
        margin: 0 20px;
        li {
          padding: 16px 0 14px;
          margin: 0 12px;
          cursor: pointer;
          @include fix-margin;
          &.active {
            border-bottom: 2px solid $color-accent;
          }
        }
      }
    }
  }
}
