@import "../_vars";
.Select {
  position: relative;
  width: 4em;
  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-bottom: 1px solid $color-accent;
    border-right: 1px solid $color-accent;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) rotate(45deg);
    margin-top: -2px;
    pointer-events: none;
  }
  select {
    background-color: $color-light;
    font-size: larger;
    border: 1px solid $color-accent;
    padding-left: 4px;
    appearance: none;
    width: 100%;
    cursor: pointer;
  }
}
