@import "../_vars";
@import "../_mixins";
.Alert {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  .container {
    max-width: 600px;
    margin: 0 auto;
    background-color: $color-light-2;
    padding: 1em;
    box-sizing: border-box;
    @include shadow;
  }
}
