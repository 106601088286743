.Breadcrumbs {
  ul {
    display: inline-flex;
    flex-wrap: wrap;
    li {
      white-space: nowrap;
      &::before {
        content: " - ";
        padding-left: 0.5em;
        padding-right: 0.25em;
      }
      &:first-child::before {
        content: unset;
      }
    }
  }
}
