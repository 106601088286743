@import "./_vars";

@mixin fix-margin {
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

@mixin shadow($br: 4px) {
  border-radius: $br;
  box-shadow: 2px 8px 15px -2px rgba(0, 0, 0, 0.153);
}

@mixin clickable($speed: 0.3s) {
  cursor: pointer;
  transition: box-shadow $speed ease-in-out, background-color $speed ease-in-out,
    color $speed ease-in-out;
  &:hover {
    @include shadow(0);
    background-color: darken($color-accent, 10);
    color: darken($color-light, 10);
  }
}
@mixin breakpoint-desktop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
