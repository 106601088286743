@import "../_vars";
@import "../_mixins";
.Product {
  box-sizing: border-box;
  border: 4px solid transparent;
  transition: border-color 0.3s ease-in-out;
  background-color: #e6e6e6;
  color: $color-dark;
  overflow: hidden;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  @include breakpoint-desktop {
    flex-direction: row;
    margin: 0 1em;
    @include fix-margin;
  }
  .data {
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div {
      display: flex;
      justify-content: space-between;
      span {
        font-size: larger;
        color: $color-accent;
      }
    }
  }
  & > * {
    flex: 1 1 0;
    img {
      width: 100%;
      max-height: 250px;
      object-fit: cover;
    }
  }
}
