@import "../_vars";
@import "../_mixins";
.Scene {
  padding: 1em 1em 3em;
  @include breakpoint-desktop {
    padding: 1em 2em 3em;
  }
  .title {
    font-size: 2em;
    text-align: center;
  }
  .options {
    display: flex;
    justify-content: center;
  }
  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint-desktop {
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
    }
  }
  .navigation {
    margin-top: 2em;
    display: flex;
    justify-content: flex-end;
  }
}
