@import "../_vars";
@import "../_mixins";
.Option {
  flex: 1 1 1;
  box-sizing: border-box;
  border: 4px solid transparent;
  margin: 0 0.5em;
  transition: border-color 0.3s ease-in-out;
  background-color: $color-light-2;
  color: $color-dark;
  overflow: hidden;
  max-width: 250px;
  width: 100%;
  @include fix-margin;
  @include clickable;

  &.selected {
    border-color: $color-accent;
  }
  img {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
  }
  .title {
    text-align: center;
  }
}
