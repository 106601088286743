@import "../_mixins";
.Checkout {
  .navigation {
    text-align: right;
  }
  .table {
    & > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 1px solid $color-accent;
      margin-bottom: 1em;
      @include breakpoint-desktop {
        flex-wrap: nowrap;
      }
      & > div {
        margin: 0.5em 1em;
        order: 1;
        @include breakpoint-desktop {
          margin: 1em 2em;
          flex-wrap: nowrap;
        }
        @include fix-margin;
        img {
          max-width: 100px;
          object-fit: cover;
        }
        &:nth-child(2) {
          flex: 1 1 1;
          width: 100%;
          order: 4;
          font-size: larger;
          margin: 0 0 2em 0;
          @include breakpoint-desktop {
            flex: 1;
            order: 1;
            margin: 1em 2em;
          }
        }
        &:last-child {
          min-width: 65px;
        }
      }
    }
    .total {
      font-size: 2em;
      text-align: right;
      font-weight: bold;
    }
  }
}
