@import "../_vars";
.Layout {
  background-color: $color-light-2;
  width: 100%;
  min-height: 100vh;
  .wrapper {
    background-color: $color-light;
    max-width: 1280px;
    margin: 0 auto;
  }
}
