@import "../_vars";
@import "../_mixins";
.CollapsableNav {
  text-align: left;
  overflow: hidden;
  &.active {
    p::after {
      transform: translateY(-50%) rotate(225deg);
      transition: transform 1s cubic-bezier(0, 1, 0, 1);
    }
    nav {
      max-height: 1000px;
      transition: max-height 1s ease-in-out;
    }
  }
  p {
    font-weight: bold;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%) rotate(45deg);
      transition: transform 0.5s cubic-bezier(0, 1, 0, 1);
      margin-top: -2px;
      pointer-events: none;
    }
    @include breakpoint-desktop {
      &::after {
        content: unset;
      }
    }
  }
  nav {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    @include breakpoint-desktop {
      max-height: 100%;
    }
  }
}
